import {Component, Input, OnInit} from '@angular/core'
import {FormControl, ReactiveFormsModule} from '@angular/forms'
import {Observable} from 'rxjs'
import {map, startWith} from 'rxjs/operators'
import {MatFormField, MatHint, MatLabel} from '@angular/material/form-field'
import {
  MatAutocomplete,
  MatAutocompleteTrigger,
  MatOption
} from '@angular/material/autocomplete'
import {AsyncPipe} from '@angular/common'
import {MatInput} from '@angular/material/input'
import {SortPipe} from '../../../../pipes/sort.pipe'
import {I18nPipe} from '../../../../i18n/i18n.pipe'
import {Ti18nKey} from '../../../../i18n/i18n-keys'

@Component({
  selector: 'kdl-project-form-field',
  templateUrl: './general-settings.component.html',
  styleUrl: './general-settings.component.scss',
  imports: [
    MatFormField,
    ReactiveFormsModule,
    MatAutocompleteTrigger,
    AsyncPipe,
    MatInput,
    MatAutocomplete,
    MatOption,
    MatLabel,
    SortPipe,
    MatHint,
    I18nPipe
  ]
})

export class GeneralSettingsComponent implements OnInit {

  @Input() public label: Ti18nKey
  @Input() public control: FormControl = new FormControl<string | null>(null)
  @Input() public optionsMap = new Map<string, string>()
  @Input() hint: Ti18nKey
  @Input() width = 160
  public filteredOptions: Observable<string[]>

  public ngOnInit(): void {
    this.filteredOptions = this.control.valueChanges.pipe(
      startWith(''),
      map(value => this.filter(value))
    )
  }

  private filter(value: string): string[] {
    const filterValue = value.toLowerCase()
    const result: string[] = []
    this.optionsMap.forEach((opt: string) => {
      if (opt.toLowerCase().includes(filterValue)) {
        result.push(opt)
      }
    })
    return result
  }

}
