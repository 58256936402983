<mat-card [ngClass]="{'card-cloak': cloak}">
  @if (project) {
    <form [formGroup]="form">
      <!-------------- GROUP 1 -------------->
      <div [ngClass]="{cloak}" class="group">
        <!-- Project Name -->
        <div
          [ngClass]="{ 'hide-form-control' : formControlToHide['projectName'] }"
          class="input-holder">
          <mat-form-field [style.width.px]="350">
            <mat-label>{{ 'project_form_name' | i18n }}</mat-label>
            <input formControlName="customerName"
                   matInput
                   type="text">
            <mat-hint>{{ 'project_form_name_hint' | i18n }}</mat-hint>
          </mat-form-field>
        </div>

        <!-- Approximate delivery date -->
        <div
          [ngClass]="{ 'hide-form-control' : formControlToHide['approxDeliveryDate'] }"
          class="input-holder">
          <mat-form-field>
            <mat-label>{{ 'project_form_approximate_delivery' | i18n }}
            </mat-label>
            <input [matDatepicker]="picker"
                   [min]="minDate"
                   formControlName="approxDeliveryDate"
                   matInput>

            <mat-datepicker-toggle [for]="picker"
                                   matIconSuffix></mat-datepicker-toggle>
          </mat-form-field>
          <mat-datepicker #picker></mat-datepicker>
        </div>
      </div>
      <!----------- End of GROUP 1 ----------->

      <!-------------- GROUP 2 -------------->
      <div [ngClass]="{cloak}" class="group">
        <!-- Customer Version - customerVersion -->
        <div
          [ngClass]="{ 'hide-form-control' : formControlToHide['customerVersion'] }"
          class="input-holder">
          <mat-form-field>
            <mat-label>{{ 'project_form_customer_version' | i18n }}</mat-label>
            <input class="kdl-no-flippers"
                   formControlName="customerVersion"
                   matInput
                   type="number">
            <mat-hint>{{ 'project_form_customer_version_hint' | i18n }}</mat-hint>
          </mat-form-field>
        </div>

        <!-- Country, should be checked how this relates to Customer -->
        <mat-form-field
          [ngClass]="{ 'hide-form-control' : formControlToHide['lc'] }">
          <mat-label>{{ 'common_country' | i18n }}</mat-label>
          <mat-select [placeholder]="'project_select_country' | i18n"
                      formControlName="lc" id="country" ngDefaultControl>
            <mat-option value="sv">{{ 'common_country_sweden' | i18n }}
            </mat-option>
            <mat-option value="fi">{{ 'common_country_finland' | i18n }}
            </mat-option>
            <mat-option value="da">{{ 'common_country_denmark' | i18n }}
            </mat-option>
            <mat-option value="nb">{{ 'common_country_norway' | i18n }}
            </mat-option>
            <mat-option value=""></mat-option>
          </mat-select>
        </mat-form-field>

        <!-- Socel Height -->
        <div
          [ngClass]="{ 'hide-form-control' : formControlToHide['socelHeight'] }"
          class="input-holder">
          <mat-form-field>
            <mat-label>{{ 'product_form_sockel_height' | i18n }}</mat-label>
            <input class="kdl-no-flippers" formControlName="socelHeight"
                   matInput type="number">
            <span matTextSuffix="">mm</span>
            <mat-hint>{{ 'product_form_sockel_height_hint' | i18n }}</mat-hint>
          </mat-form-field>
        </div>
      </div>
      <!----------- End of GROUP 2 ----------->

      <!-------------- GROUP 3 -------------->
      <div [ngClass]="{cloak: cloak}" class="group">
        <!-- Total customer price, auto calculated and non-editable -->
        <!-- Price is hidden if no country ("lc") is selected -->
        <div
          [ngClass]="{ 'hide-form-control' : formControlToHide['customerPrice'] || !form.controls.lc.value }"
          class="input-holder">
          <mat-form-field class="narrow">
            <mat-label>{{ 'product_form_price' | i18n }}</mat-label>
            <!-- Project customer price cannot be edited -->
            <input class="kdl-no-flippers"
                   [value]="project.pricing.totalCustomerPrice | number:'1.0-0':'fr' "
                   disabled
                   matInput>
            <span matTextSuffix="">kr</span>

            @if (form.controls.priceAdjustments.length) {
              <mat-hint>
                {{ 'product_form_price_hint' | i18n }}:
                {{ project.pricing.originalCustomerPrice | number:'1.0-0':'fr' }}
              </mat-hint>
            }
          </mat-form-field>
        </div>

        <!-- Locking of prices -->
        @if (project.priceLockTime) {
          <div
            [ngClass]="{ 'hide-form-control' : formControlToHide['customerPrice'] || !form.controls.lc.value }">
            <span>
              {{ 'product_form_price_locked' | i18n }}
              {{ project.priceLockTime | date: 'd MMMM yyyy' }}.
            </span>

            <div class="price-lock">
              <span>{{ 'product_form_price_locking' | i18n }}</span>

              <button class="yes-button" (click)="resetPriceLock()" color="warn"
                      mat-stroked-button>{{ 'common_yes' | i18n }}
              </button>
            </div>
          </div>
        }
      </div>
      <!----------- End of GROUP 3 ----------->

      <!-------------- GROUP 4 - Adjustments -------------->
      <!-- Adjustments are hidden if no country ("lc") is selected -->
      <div
        class="group price-adjustment-row"
        [ngClass]="{ 'hide-form-control' : formControlToHide['discount'] || !form.controls.lc.value, cloak }">
        <!-- Price adjustments -->
        @for (control of form.controls.priceAdjustments.controls; track $index) {
          <div class="price-adjustment-form-field">
            <mat-form-field>
              <mat-label>{{ 'product_form_price_adjustments' | i18n }}</mat-label>
              <input
                class="kdl-no-flippers"
                [formControl]="control"
                matInput
                type="number">

              <span matTextSuffix="">kr</span>
            </mat-form-field>

            <button mat-icon-button color="warn" class="remove-button"
                    (click)="removePriceAdjustment($index)">
              <mat-icon class="material-icons-outlined">cancel</mat-icon>
            </button>
          </div>
        }

        <button mat-icon-button color="primary" (click)="addPriceAdjustment()">
          <mat-icon class="material-icons-outlined">add_circle</mat-icon>
        </button>
      </div>
      <!-------------- End of GROUP 4 -------------->

      <!-------- Installation options -------->
      <!-------------- GROUP 5 -------------->
      <div
        [ngClass]="{ 'hide-form-control' : formControlToHide['assemblyData'], cloak }"
        class="group">
        <!-- Assembly customer price cannot be edited -->
        <mat-form-field>
          <mat-label>{{ 'product_form_assembly_price' | i18n }}</mat-label>
          <input
            class="kdl-no-flippers"
            [value]="project.pricing.assemblyCustomerPrice | number:'1.0-0':'fr' "
            disabled
            matInput>
          <span matTextSuffix="">kr</span>
        </mat-form-field>

        <mat-checkbox formControlName="assemblyToCustomer" ngDefaultControl>
          {{ 'product_form_assembly' | i18n }}
        </mat-checkbox>
      </div>
      <!----------- End of GROUP 5 ----------->

      <!-------------- GROUP 6 -------------->
      @if (form.controls.assemblyToCustomer.value) {
        <div
          [ngClass]="{ 'hide-form-control' : formControlToHide['assemblyData'], cloak }"
          class="group">
          <!-- Assembly Options -->
          <kdl-assembly-options [projectForm]="project.form">
          </kdl-assembly-options>
        </div>
      }
      <!----------- End of GROUP 6 ----------->
      <!---- End of Installation options ---->

      <!-------------- GROUP 7 -------------->
      <div [ngClass]="{ cloak }" class="group">
        <!-- Paint process -->
        <mat-form-field
          [ngClass]="{ 'hide-form-control' : formControlToHide['paintProcess'] }"
          [style.width.px]="350">
          <mat-label>{{ 'product_form_paint_process' | i18n }}</mat-label>
          <mat-select [placeholder]="'product_form_paint_process' | i18n"
                      formControlName="paintProcess"
                      panelClass="no-max-height"
                      ngDefaultControl
                      panelWidth="350px">
            @for (opt of PaintProcessValues; track opt) {
              <mat-option [value]="opt">
                {{ opt | paintProcess:'i':'en' }}
              </mat-option>
            }
          </mat-select>
        </mat-form-field>
        <!-- Color -->
        @if (!hideColor) {
          <div
            [ngClass]="{ 'hide-form-control' : formControlToHide['color'] }"
            class="input-holder">
            <kdl-project-form-field
              [control]="form.controls.color"
              [optionsMap]="colors"
              [width]="250"
              hint="project_form_colour_hint"
              label="project_form_colour"></kdl-project-form-field>
          </div>
        }
      </div>
      <!----------- End of GROUP 7 ----------->

      <!-------------- GROUP 8 -------------->
      <div [ngClass]="{cloak: cloak}" class="group">
        <!-- Carpentry -->
        <kdl-project-form-field
          [control]="form.controls.carpentry"
          [ngClass]="{ 'hide-form-control' : formControlToHide['carpentry'] }"
          [optionsMap]="carpenters"
          label="project_form_carpentry"
        ></kdl-project-form-field>

        <!-- CAD -->
        <kdl-project-form-field
          [control]="form.controls.cad"
          [ngClass]="{ 'hide-form-control' : formControlToHide['cad'] }"
          [optionsMap]="caders"
          label="project_form_cad"
        ></kdl-project-form-field>

        <!-- Painter -->
        @if (!hidePainter) {
          <kdl-project-form-field
            [control]="form.controls.painter"
            [ngClass]="{ 'hide-form-control' : formControlToHide['painter'] }"
            [optionsMap]="painters"
            label="project_form_painter"
          ></kdl-project-form-field>
        }
      </div>
      <!----------- End of GROUP 8 ----------->

      <!-------------- GROUP 9 -------------->
      <div
        [ngClass]="{ 'hide-form-control' : formControlToHide['hideFactoryInvoice'] }">
        <mat-checkbox formControlName="hideFactoryInvoice" ngDefaultControl>
          {{ 'project_form_hide_factory_price' | i18n }}
        </mat-checkbox>
      </div>

      <div
        [ngClass]="{ 'hide-form-control' : formControlToHide['hideRecommendedAppliances'] }">
        <mat-checkbox formControlName="hideRecommendedAppliances"
                      ngDefaultControl>
          {{ 'project_form_hide_recommended_appliances' | i18n }}
        </mat-checkbox>
      </div>
      <!----------- End of GROUP 9 ----------->
    </form>
  }
</mat-card>
