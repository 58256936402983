import {Component, DestroyRef, inject, Input, OnInit} from '@angular/core'
import {
  FormArray,
  FormControl,
  FormGroup,
  ReactiveFormsModule
} from '@angular/forms'
import {MatDialog} from '@angular/material/dialog'
import {DomSanitizer} from '@angular/platform-browser'
import {forkJoin, of} from 'rxjs'
import {
  debounceTime,
  filter,
  first,
  switchMap,
  withLatestFrom
} from 'rxjs/operators'
import {CustomerProject} from '../../../customer/model/customer-project.class'
import {
  ConfirmDialogComponent
} from '../../../dialogs/confirm-dialog/confirm-dialog.component'
import {OpenProjectService} from '../../../services/open-project.service'
import {
  IProject,
  PaintProcessValues,
  ProjectFormData,
  TPaintProcessValue
} from '../../../services/project-types'
import {SettingsItemService} from '../../../services/settings-item.service'
import {MatCard} from '@angular/material/card'
import {DatePipe, DecimalPipe, NgClass} from '@angular/common'
import {
  MatFormField,
  MatHint,
  MatLabel,
  MatSuffix
} from '@angular/material/form-field'
import {MatInput} from '@angular/material/input'
import {
  MatDatepicker,
  MatDatepickerInput,
  MatDatepickerToggle
} from '@angular/material/datepicker'
import {MatOption, MatSelect} from '@angular/material/select'
import {MatIcon} from '@angular/material/icon'
import {MatButton, MatIconButton} from '@angular/material/button'
import {MatCheckbox} from '@angular/material/checkbox'
import {
  GeneralSettingsComponent
} from './general-settings/general-settings.component'
import {PaintProcessPipe} from '../../../pipes/paint-process.pipe'
import {
  AssemblyOptionsComponent
} from '../../assembly-options/assembly-options.component'
import {takeUntilDestroyed} from '@angular/core/rxjs-interop'
import {I18nPipe} from '../../../i18n/i18n.pipe'
import {MatNativeDateModule} from '@angular/material/core'

/**
 * Do not show color field if any of these
 */
export const hiddenColors: TPaintProcessValue[] = [4, 5]

/**
 * Do not show painter field if any of these,
 * only 1 (line seed) and 3 (normal paint) should
 * be shown
 */
export const hiddenPainters: TPaintProcessValue[] = [2, 4, 5, 6, 7, 8]


@Component({
  selector: 'kdl-project-form',
  templateUrl: './edit-project.component.html',
  styleUrls: ['./edit-project.component.scss'],
  imports: [
    MatCard,
    NgClass,
    ReactiveFormsModule,
    MatFormField,
    MatLabel,
    MatInput,
    MatHint,
    MatSuffix,
    MatDatepickerInput,
    MatDatepickerToggle,
    MatNativeDateModule,
    MatSelect,
    MatOption,
    DecimalPipe,
    DatePipe,
    MatIcon,
    MatIconButton,
    MatButton,
    MatCheckbox,
    GeneralSettingsComponent,
    PaintProcessPipe,
    AssemblyOptionsComponent,
    MatDatepicker,
    I18nPipe
  ]
})
export class EditProjectComponent implements OnInit {

  /**
   * This is to display individual form controls in the warning dialog
   */
  @Input() public show: string[] = []

  /**
   * Hides all padding if we are in partial mode.
   */
  public cloak = false

  public formControlToHide: Record<string, boolean> = {
    projectName: false,
    discount: false,
    customerPrice: false,
    assemblyData: false,
    customerVersion: false,
    projectId: false,
    value: false,
    color: false,
    paintProcess: false,
    lc: false,
    socelHeight: false,
    hideFactoryInvoice: false,
    hideRecommendedAppliances: false,
    carpentry: false,
    cad: false,
    painter: false,
    approxDeliveryDate: false
  }

  /**
   * The form for setting project specific data
   */
  public form = new FormGroup({
    customerName: new FormControl<string | null>(null),
    priceAdjustments: new FormArray<FormControl<number>>([]),
    assemblyPrice: new FormControl<number>(null),
    assemblyToCustomer: new FormControl<boolean>(false),
    customerVersion: new FormControl(),
    color: new FormControl<string | null>(null),
    paintProcess: new FormControl<TPaintProcessValue | null>(null),
    lc: new FormControl<string>('', {nonNullable: true}),
    socelHeight: new FormControl<number | null>(null),
    carpentry: new FormControl<string | null>(null),
    cad: new FormControl<string>(null),
    painter: new FormControl<string>(null),
    hideFactoryInvoice: new FormControl<boolean>(false),
    hideRecommendedAppliances: new FormControl<boolean>(true),
    approxDeliveryDate: new FormControl<string>(null)
  })

  /**
   * A list of colors to be selected in the color form control
   */
  public colors: Map<string, string>
  /**
   * A list of carpenters to be selected in the carpentry form control
   */
  public carpenters: Map<string, string>
  /**
   * A list of "CAD:ers" to be selected in the cad form control
   */
  public caders: Map<string, string>
  /**
   * A list of painter to be selected in the painter form control
   */
  public painters: Map<string, string>

  /**
   * Flag to hide colour
   */
  public hideColor = false
  /**
   * Flag to hide the painter if he should not paint
   */
  public hidePainter = false

  // Helpful variables to display in HTML
  protected readonly PaintProcessValues = PaintProcessValues
  protected minDate: Date = new Date()
  protected project: IProject

  protected openProjectService = inject(OpenProjectService)
  private readonly settingsItemService = inject(SettingsItemService)
  private readonly dialog = inject(MatDialog)
  private readonly sanitizer = inject(DomSanitizer)
  private readonly destroyRef = inject(DestroyRef)

  public ngOnInit(): void {
    // Populate lists for mat-selects
    this.painters = this.settingsItemService.getSettingConfigAsMap('PAINTER')
    this.caders = this.settingsItemService.getSettingConfigAsMap('CAD')
    this.colors = this.settingsItemService.getSettingConfigAsMap('COLOR')
    this.carpenters = this.settingsItemService.getSettingConfigAsMap('CARPENTRY')

    // Save project for the first time without any debounce time.
    this.openProjectService.project$
      .pipe(
        filter(Boolean),
        first()
      )
      .subscribe((p) => {
        this.project = p
        if (p.form.hideRecommendedAppliances === undefined) {
          p.form.hideRecommendedAppliances = true
        }
        // Patch all its "form" values into our form here
        this.form.patchValue(p.form, {emitEvent: false})
      })

    this.openProjectService.project$.pipe(
      takeUntilDestroyed(this.destroyRef),
      filter(Boolean),
      // By adding a debounce time here we delay "form.patchValue()", which
      // will allow users to write negative numbers by first typing '-' in a
      // 1000ms window. It's tricky, but it works :) - Darío 27-08-2024
      debounceTime(1000),
      // We get current project with openProjectService observable.
      switchMap((project: IProject) => {
        // Save current project to use it later
        this.project = project

        // Create all needed form controls for adjustments first, otherwise
        // we will receive an error.
        this.form.controls.priceAdjustments.clear()
        this.project.form.priceAdjustments
          ?.forEach(() => this.addPriceAdjustment())

        // Patch all its "form" values into our form here
        this.form.patchValue(project.form, {emitEvent: false})

        // Calculate some flag values according to project data
        this.hideColor = hiddenColors.includes(project.form.paintProcess)
        this.hidePainter = hiddenPainters.includes(project.form.paintProcess)

        // Manually set some form controls and variables with project data
        this.form.controls.customerName.setValue(project.customer.name, {emitEvent: false})

        // Start listening to form updates
        return this.form.valueChanges
      }),
      // Once we have a project set. We listen to form updates to set those
      // values into project.
      switchMap((val: ProjectFormData) => {
        this.project.customer.name = val.customerName
        this.hideColor = hiddenColors.indexOf(val.paintProcess) !== -1
        this.hidePainter = hiddenPainters.indexOf(val.paintProcess) !== -1
        return this.openProjectService.setProjectForm(val)
      })
    ).subscribe()

    this.form.controls.color.valueChanges
      .pipe(
        debounceTime(1000),
        withLatestFrom(this.openProjectService.customerProject$)
      )
      .subscribe({
        next: (res: [string, CustomerProject]) => {
          const cp: CustomerProject = res[1]
          /**
           * TBD or empty are counted as regular
           */
          const regular = [...this.colors.values()]
            .map(c => c.toLowerCase())
            .concat(['', 'tbd']).indexOf(res[0].toLowerCase()) !== -1
          const updated = cp.setCustomColor(!regular)
          this.openProjectService.triggerChanges({customerProjectChange: updated})
        }
      })

    this.form.controls.paintProcess.valueChanges
      .pipe(
        switchMap((val: TPaintProcessValue) =>
          forkJoin([of(val), this.openProjectService.customerProject$
            .pipe(first())])
        ))
      .subscribe({
        next: (res: [TPaintProcessValue, CustomerProject]) => {
          const cp: CustomerProject = res[1]
          // Update the customer project with info about color process?!
          cp.setColorProcess(res[0] === 1 || res[0] === 3)
          this.openProjectService.triggerChanges({customerProjectChange: true})
        }
      })
    this.showHideFormControls()

    this.minDate.setDate(this.minDate.getDate())
  }

  public addPriceAdjustment(): void {
    this.form.controls.priceAdjustments
      .push(new FormControl<number>(0, {nonNullable: true}))
  }

  public removePriceAdjustment(index: number): void {
    this.form.controls.priceAdjustments.removeAt(index)
  }

  public resetPriceLock(): void {
    ConfirmDialogComponent.open(this.dialog, {
      html: this.sanitizer.bypassSecurityTrustHtml(
        `You are about to update the project to the current price list.<br>Are you absolutely sure?`)
    })
      .afterClosed()
      .pipe(
        filter(Boolean)
      ).subscribe({
      next: () => {
        this.project.priceLockTime = Date.now()
        this.openProjectService.triggerChanges({projectChange: true})
      }
    })
  }

  private showHideFormControls(): void {
    if (this.show.length > 0) {
      this.cloak = true
      Object.keys(this.formControlToHide).forEach((key: string) => {
        this.formControlToHide[key] = true
      })

      this.show.forEach((show: string) => {
        this.formControlToHide[show] = false
      })
    }
  }
}
