<mat-checkbox (change)="setOption('assemblyCarry', $event.checked)"
              [checked]="projectForm().assemblyCarry">
  {{ 'project_assembly_carrying' | i18n}}
</mat-checkbox>

<mat-checkbox (change)="setOption('assemblyWaste', $event.checked)"
              [checked]="projectForm().assemblyWaste">
  {{ 'project_assembly_waste' | i18n}}
</mat-checkbox>

<mat-checkbox (change)="setOption('assemblyMeasure', $event.checked)"
              [checked]="projectForm().assemblyMeasure">
  {{ 'project_assembly_measuring' | i18n}}
</mat-checkbox>
